<template></template>

<script>
export default {
  name: 'Redirect',
  asyncData ({ context, route }) {
    return new Promise((resolve) => {
      if (context) {
        context.output.cacheTags.add(`redirect`);
        if (route.params.code) context.server.response?.status(route.params.code)
        if (route.params.slug) context.server.response?.header('location', route.params.slug)
      } else {
        window.location = route.params.slug
      }
      resolve();
    })
  }
};
</script>
